import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoIosMenu } from "react-icons/io";

const Header = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveTab(1);
    } else if (location.pathname === "/proyectos") {
      setActiveTab(4);
    } else if (location.pathname === "/contacto") {
      setActiveTab(3);
    }
    setShowMenu(false);
  }, [location]);

  const setClassNameTab = (value) => {
    return activeTab === value ? "active" : "inactive";
  };

  return (
    <header className="header-component">
      <nav className="navbar navbar-expand-lg ">
        <button
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowMenu(!showMenu)}>
          <IoIosMenu className="menu-icon" />
        </button>
        <div
          className={`collapse navbar-collapse ${showMenu ? "show" : " "}`}
          id="navbarSupportedContent">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" className={`nav-item nav-link ${setClassNameTab(1)}`}>
                me
              </Link>
            </li>
            <li className="nav-item">
              <span
                className="nav-item nav-link"
                onClick={() => window.open("https://www.martingoiriz.com.ar/blog")}>
                Blog
              </span>
            </li>
            {/* <li className="nav-item disabled">
              <Link
                to="/proyectos"
                className={`nav-item nav-link ${setClassNameTab(4)}`}>
                Proyectos
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to="/contacto" className={`nav-item nav-link ${setClassNameTab(3)}`}>
                Contacto
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
